<!-- Tooltip.vue -->
<template>
  <div v-if="showTooltip" class="tooltip" @click.stop>
    <div class="tooltip-title">学习指引（一）</div>
    <div class="tooltip-content">
      请先注册，个人用户请点击个人注册，
      企业/单位用户请点击企业注册。
    </div>
    <div class="buttons-container">
      <button @click.stop="nextStep">下一步</button>
      <button @click.stop="closeTooltip">关闭提醒</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['showTooltip'],
  methods: {
    nextStep() {
      // 这里可以放置点击“下一步”按钮后的逻辑
      this.$emit('next-clicked');
      // console.log('进入下一步');
    },
    closeTooltip() {
      this.$emit('close-clicked');
    }
  }
}
</script>

<style scoped>
.tooltip {
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 5px;  /* 调整内边距为5px */
  z-index: 1000;
  width: 150px;  /* 调整宽度为150px */
  line-height: 1.5;  /* 调整行高为1.5 */
}

.tooltip::before {
  content: '';
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #ccc;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f9f9f9;
}

.tooltip-title {
  font-weight: bold;
  font-size: 12px; /* 调整字体大小为12px */
  margin-bottom: 4px; /* 调整边距为4px */
}

.tooltip-content {
  font-size: 10px; /* 调整字体大小为10px */
  margin-bottom: 4px; /* 调整边距为4px */
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 4px; /* 调整边距为4px */
}

.buttons-container button {
  padding: 4px 8px; /* 调整内边距为4px 8px */
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px; /* 调整圆角为4px */
  font-size: 10px; /* 调整字体大小为10px */
}

.buttons-container button:hover {
  background-color: #0056b3;
}
</style>

