<template>
  <div class="header">
    <div class="wrapin">
      <a href="" class="logo">

        <img
            src='../assets/logo4.png'
            alt=""
            class="logoimg"
        />
        <!-- <span class="ft1">  乌鲁木齐建设职业培训中心</span> -->
      </a>
      <div class="nav_wrap" style="display:none">
        <a href="/" class="hover">首页</a>
        <div class="dropdown">
          <span>开课通知</span>
        </div>
        <div class="dropdown">
          <span>最新开班</span>
        </div>
        <div class="dropdown">
          <span>问答</span>
        </div>
        <div class="dropdown">
          <span>题库自测</span>
          <div class="dropdown-content" id="test">
            <a href="" style="font-size: 16px">模拟考试</a>
            <a href="" style="font-size: 16px">习题练习</a>
          </div>
        </div>
        <div class="dropdown">
          <span>资质</span>
        </div>
        <div class="dropdown" style="float: right">
          <a href="" class="helpprobiem">常见问题</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav2'
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header .logo {
  float: left;
  display: flex;
  align-items: center;
  font-size: 28px;
  color: #004e9f;
  font-weight: bold;
  background-image: linear-gradient(#0747b6, #06386a);

  background-clip: text;

  -webkit-background-clip: text;

  color: transparent;
  .logoimg{
    height: 90px;
    margin-right:20px;
    position: relative;
  }
}
.header{
  height: 95px;
  box-shadow: 0px 3px 4px 0 #ddd;
  background-color: #fff;
  margin-top: 1px;
  .wrapin{
    width: 1170px;
    margin: 0 auto;

    .nav_wrap{
      margin-top: 25px;
      float: right;
      line-height: 40px;
      font-size: 16px;
      a {
        display: inline-block;
        padding: 0 20px;
        color: #666;
        text-decoration: none;
      }
      a:hover{
        color: #060606;
      }
      .dropdown {
        position: relative;
        display: inline-block;
        span {
          padding: 20px;
        }
        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #f9f9f9;
          min-width: 160px;
          box-shadow: 0px 8px 16px 0px;
          padding: 12px 16px;
        }
        .helpprobiem{
          float: right;
          font-size: 14px;
          line-height: 40px;
          padding-left: 16px;
          background: url(https://stu.xadi.com.cn/static/default/img/index/helpa.png) no-repeat left center;
        }
      }
    }
  }

}
.ft1{
  font-family: Xingkai SC ,Weibei SC;
}
</style>