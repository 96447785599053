<template>
  <!-- 头部开始 -->
  <div class="header_t" id="top">
    <div class="wrapin">
      <div class="lt">您好, {{userName}} 欢迎归来！<!--<router-link :to="{name: 'course'}" tag="i" style="color:#ff452c">个人中心></router-link><i @click="goCenter" style="color:#ff452c">个人中心></i>--></div>
      <div class="rt">
<!--        <span @click="goFavorite" class="sca">加入收藏</span>-->
        <template v-if="!token">
          <div v-if="!token" class="fg"></div>
<!--          <Overlay :visible="showTooltip" />-->
          <span v-if="!token" @click="goRegister(1)" class="test-1">个人注册
            <Tooltip :showTooltip="showTooltip"  @next-clicked="hideTooltipAndOverlay" @close-clicked="closeTooltip" />
          </span>
          <slot></slot>
          <div v-if="!token" class="fg"></div>
          <span v-if="!token" @click="goRegister(2)">企业注册</span>
          <div class="fg"></div>
          <span v-if="!token" @click="goLogin">登录</span>
        </template>
        <template v-else>
          <div class="fg"></div>
          <span v-if="role === 1" @click="goCenter">个人中心</span>
          <span v-if="role === 2" @click="goEntCenter">企业中心</span>
          <div class="fg"></div>
          <span  @click="goOut">退出</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import { logout } from '@/api/login'
// import { sendSMS, userNameCheck } from "@/api/login";
import { mapGetters } from 'vuex'
import Tooltip from './Tooltip.vue';
// import Overlay from './Overlay.vue';

export default {
  name: "HeaderT",
  props: {
    headerTooltip: {
      type: [Boolean],  // 根据实际数据类型设置
      default: true
    }
  },
  data() {
    return {
      showTooltip: false,
      tooltipStyle: {}
    };
  },
  components: {
    Tooltip,
    // Overlay
  },
  computed: {
    ...mapGetters([
      'nickName',
      'phone',
      'userName',
      'token',
      'role'
    ])
  },
  created() {
    console.log(this.role);
    this.checkLoginAndShowTooltip();

    // console.log('cc', this.token, this.nickName, this.phone,this.userName);
  },
  mounted() {
    this.handleTooltipNextClick(1);
  },
  methods: {
    checkLoginAndShowTooltip() {
      // let fullUrl = window.location.href;
      // console.log("fullUrl" + fullUrl);
      let currentPath = this.$route.path;
      console.log("currentPath"+ currentPath);
      if(undefined !== currentPath && currentPath !== "/") {
        this.showTooltip = false;
        return
      }
      // console.log("currentPath"+ currentPath);
      if(this.token){
        //已登录
        this.$emit('send-data', "close");
        return;
      }
      setTimeout(() =>{
        this.$emit('send-data', "start");
        this.showTooltip = true;
      } , 500)
    },
    showPopupTooltip() {
      this.showTooltip = true;
    },
    goRegister(type) {
      this.$router.push({
        path: "/register",
        query: {
          type: type
        }
      });
    },
    hideTooltipAndOverlay() {
      this.showTooltip = false;
      this.handleTooltipNextClick(2);
    },
    closeTooltip() {
      this.showTooltip = false;
      this.handleTooltipNextClick(3);
    },
    handleTooltipNextClick(data) {
      if(data === 2) {
        this.$emit('send-data', "next");
      }else if(data === 3) {
        this.$emit('send-data', "close");
      }
    },
    goLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    goFavorite() {
      const title = "乌鲁木齐建设职业培训中心";
      const url = window.location.href;
      try {
        window.external.addFavorite(url, title);
      } catch (e) {
        try {
          window.sidebar.addPanel(title, url, "");
        } catch (e) {
          alert(
            "抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请使用Ctrl+D进行添加"
          );
        }
      }
    },
    async goOut() {
      await this.$store.dispatch('user/logout').then(res=>{
        console.log(res);
        if(res.status === 200) {
          if(res.data.code === 0) {
            this.$router.push({
              path: '/'
            })
            setTimeout(() =>{
              location.reload()
            },100)
          }
        }
      })
      
      


      // logout().then(res=>{
      //   console.log(res)
      // })
      // sendSMS({
      //   phone: "18665035791",
      //   smsType: 1,
      // }).then((res) => {
      //   console.log(res);
      // });
      // userNameCheck({
      //   value: '18665035791'
      // }).then((res) => {
      //   console.log(res);
      // });
    },
    //个人中心
    goCenter() {
      this.$router.push({
        path: '/myCenter'
      })
    },
    //企业中心
    goEntCenter() {
      this.$router.push({
        path: '/entCenter'
      })
    }
  },
  
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header_t {
  min-width: 1170px;
  height: 50px;
  background-color: #f6f6f6;
  box-shadow: inset 0 -1px 0 0 #e8e8e8;
  line-height: 49px;
  color: #333;
  font-size: 14px;
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);  /* 半透明的背景色 */
    z-index: 1000;  /* 比页面的其他内容高，但比<span>和tooltip低 */
  }
  .wrapin {
    width: 1170px;
    margin: 0 auto;
    .sca {
      padding-left: 20px;
      background: url(https://stu.xadi.com.cn/static/default/img/index/head_sc.png)
        no-repeat left center;
    }
    span {
      float: left;
      color: #333;
      cursor: pointer;
      text-decoration: none;
    }
    .test-1 {
      position: relative;
      display: inline-block;
      z-index: 1001;
    }
    .tooltip {
      position: absolute;
      top: 100%;  /* 这将使得tooltip出现在span的正下方 */
      left: 50%;  /* 这将使得tooltip的左边与span的中心对齐 */
      transform: translateX(-50%);  /* 这将使得tooltip居中对齐与span */
    }
    .fg {
      float: left;
      height: 14px;
      border-left: 1px solid #cecece;
      margin: 19px 15px;
    }
    .lt {
      float: left;
    }
    .rt {
      float: right;
      z-index: 1001;
    }
  }
}
</style>