import request from '../utils/request'

export function getClass(params = {}) {
    return request({
        method: 'GET',
        url: 'course/category',
        params
    })
}

export function getBanner(params = {}) {
    return request({
        method: 'GET',
        url: '/getBanndr',
        params
    })
}

export function getDetail(id) {
    return request({
        method: 'GET',
        url: `/product/${id}`
    })
}

export function updateProduct(id, data) {
    return request({
        method: 'PUT',
        url: `/product/${id}`,
        data,
    })
}

//0010060-课程列表接口
export function getCourseList(params = {}) {
    return request({
        method: 'GET',
        url: 'course/course',
        params
    })
}

export function getNewlyCourseList(params = {}) {
    return request({
        method: 'GET',
        url: 'course/newlyCourse',
        params
    })
}

//0010061-课程详情接口

export function getClassDetail(id) {
    return request({
        method: 'GET',
        url: `course/${id}`
    })
}

export function getClassPeriodDetail(id) {
    return request({
        method: 'GET',
        url: `course/period/${id}`
    })
}

export function saveChoosePeriod(data) {
    return request({
        method: 'POST',
        url: 'course/saveChoosePeriod',
        data
    })
}

//0010062-查询课程是否被收藏
export function searchCollectStatus(params = {}) {
    return request({
        method: 'GET',
        url: 'collect/check',
        params
    })
}

//0010063-收藏/取消收藏课程
export function changeCollectStatus(data) {
    return request({
        method: 'POST',
        url: 'collect/change',
        data
    })
}

//0010065-(免费)课程报名接口
export function signUp(data) {
    return request({
        method: 'POST',
        url: 'course/signUp',
        data
    })
}

//0010068-地州标签接口
export function getRegion(params = {}) {
    return request({
        method: 'GET',
        url: 'course/region',
        params
    })
}

//0010068-地州标签接口
export function getRegionCourse(params = {}) {
    return request({
        method: 'GET',
        url: 'course/regionCourse',
        params
    })
}

//0010069-精品课课程列表
export function getHighQuality(params = {}) {
    return request({
        method: 'GET',
        url: 'course/highQuality',
        params
    })
}

export function getOpenCourse(params = {}) {
    return request({
        method: 'GET',
        url: 'course/openCourse',
        params
    })
}

export function getLiveCourse(params = {}) {
    return request({
        method: 'GET',
        url: 'course/liveCourse',
        params
    })
}

// 0010124-直播课程详情接口
export function getCourseLive(id) {
    return request({
        method: 'GET',
        url: `course/live/${id}`,
    })
}

// 0010125-直播课开始学习接口

export function getCourseLiveCode(params = {}) {
    return request({
        method: 'GET',
        url: 'course/live',
        params
    })
}


//地州2级
export function getRegionSecond(id) {
  return request({
    method: 'GET',
    url: `course/region/${id}`,
})
}