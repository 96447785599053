// 订单相关接口
import request from '../utils/request'
// import qs from 'qs'

//0010022-我的订单列表分页接口
export function getOrderList(params={}) {
  return request({
      method: 'GET',
      url:'order',
      params
  })
}

export function confirmOrder(params={}) {
    return request({
        method: 'GET',
        url:'order/checkOrderPay',
        params
    })
}

//0010021-取消订单接口
export function cancelOrder(data) {
  return request({
      method: 'POST',
      url: 'order/cancel',
      data
  })
}

//0010016-订单详情接口
export function getOrderDetail(orderId) {
  return request({
      method: 'GET',
      url:`order/${orderId}`,
  })
}

//0010015-提交订单接口
export function submitOrder(data) {
  return request({
      method: 'POST',
      url: 'order',
      data
  })
}

//
//0010135-企业用户线下支付订单合并提交支付凭证
export function payRecord(data) {
  return request({
      method: 'POST',
      url: 'order/offline/payRecord',
      data
  })
}


export function commentOrder(data) {
    return request({
        method: 'POST',
        url: 'order/comment',
        data
    })
}


//0010023-获取订单支付方式列表
export function getPayMethod(params={}) {
  return request({
      method: 'GET',
      url:'order/pay/method',
      params
  })
}

//0010014-避免订单重复提交
export function getTxToken(data) {
  return request({
      method: 'POST',
      url: 'tx/token/gen',
      data
  })
}

//0010017-微信扫码支付二维码接口
export function getNative(data) {
  return request({
      method: 'POST',
      url: 'wx/prepay/native',
      data
  })
}

//0010029-支付过程中查询订单是否支付
export function getIspaid(params={}) {
  return request({
      method: 'GET',
      url:'order/isPaid',
      params
  })
}

//0010024-订单退款申请接口
export function applyRefund(data) {
  return request({
      method: 'POST',
      url: 'order/refund/apply',
      data
  })
}

//0010031-我的退款列表分页接口
export function getRefundList(params={}) {
  return request({
      method: 'GET',
      url:'order/refund',
      params
  })
}

// 0010028-取消退款申请接口
export function cancelApplyRefund(data) {
  return request({
      method: 'POST',
      url: 'order/refund/cancel',
      data
  })
}

export function getOrderPayList(data) {
    return request({
        method: 'GET',
        url: 'order/payList',
        data
    })
}

export function getPayDetail(id) {
    return request({
        method: 'GET',
        url: 'order/payDetail/' + id,
    })
}
